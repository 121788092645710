import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class PushNotificationService {
    notify(title: string, body: string) {
        if ("serviceWorker" in navigator && "PushManager" in window) {
            navigator.serviceWorker.ready.then((registration) => {
                registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: environment.vapidKey // Vapid Public Key for WebPush
                }).then((subscription) => {
                    // Simulate sending subscription information to your server
                    this.sendSubscriptionToServer(subscription);

                    // Simulate receiving a push notification after 5 seconds
                    setTimeout(() => {
                        this.receivePushNotification(title, body);
                    }, 5000);
                });
            });
        }
    }

    public sendSubscriptionToServer(subscription: PushSubscription): void {
    // Simulate sending subscription data to your server
        console.log("Subscription:", subscription);
    }

    public receivePushNotification(title: string, body: string): void {
    // Simulate receiving a push notification
        const options = {
            body,
            icon: "../../assets/icons/icon-72x72.png",
        };

        navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification(title, options);
        });
    }
}
