import {
    Component, Inject, OnDestroy, OnInit
} from "@angular/core";
import { Router } from "@angular/router";
import {
    MSAL_GUARD_CONFIG,
    MsalBroadcastService,
    MsalGuardConfiguration,
    MsalModule,
    MsalService,
} from "@azure/msal-angular";
import {
    EventMessage,
    EventType,
    RedirectRequest,
} from "@azure/msal-browser";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { Subject } from "rxjs";

import { UserService } from "../core/services/user.service";

@Component({
    selector: "app-login",
    standalone: true,
    imports: [ButtonModule, CheckboxModule, ProgressSpinnerModule, MsalModule],
    templateUrl: "./login.component.html",
    styleUrl: "./login.component.scss",
})
export class LoginComponent implements OnInit, OnDestroy {
    isIframe = false;
    loginDisplay = false;
    private readonly destroying$ = new Subject<void>();

    constructor(
        private userService: UserService,
        private router: Router,
        @Inject(MSAL_GUARD_CONFIG)
        private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService
    ) {
        this.handleAuthEvents();
    }

    ngOnInit() {
        // this.authService.handleRedirectObservable().subscribe();
        // this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

        // // this.setLoginDisplay();

        // this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
        // this.msalBroadcastService.msalSubject$
        //     .pipe(
        //         filter(
        //             (msg: EventMessage) =>
        //                 msg.eventType === EventType.ACCOUNT_ADDED ||
        //                 msg.eventType === EventType.ACCOUNT_REMOVED
        //         )
        //     )
        //     .subscribe((result: EventMessage) => {
        //         if (this.authService.instance.getAllAccounts().length === 0) {
        //             window.location.pathname = "/";
        //         } else {
        //             this.setLoginDisplay();
        //         }
        //     });

        // this.msalBroadcastService.inProgress$
        //     .pipe(
        //         filter(
        //             (status: InteractionStatus) =>
        //                 status === InteractionStatus.None
        //         ),
        //         takeUntil(this.destroying$)
        //     )
        //     .subscribe(() => {
        //         this.setLoginDisplay();
        //         this.checkAndSetActiveAccount();
        //     });

        this.loginRedirect();
    }

    handleAuthEvents() {
        this.msalBroadcastService.msalSubject$.subscribe((event: EventMessage) => {
            if (event.eventType === EventType.LOGIN_FAILURE) {
                this.authService.logoutRedirect();
            }
        });
    }

    onLogin() {
        // this.oidcSecurityService.authorize();
    }

    // setLoginDisplay() {
    //     this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    //     if (this.loginDisplay) {
    //         console.log("login success");
    //         this.getUserDetails("");
    //     }
    // }

    // checkAndSetActiveAccount() {
    //     /**
    //      * If no active account set but there are accounts signed in, sets first account to active account
    //      * To use active account set here, subscribe to inProgress$ first in your component
    //      * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
    //      */
    //     const activeAccount = this.authService.instance.getActiveAccount();

    //     if (
    //         !activeAccount
    //         && this.authService.instance.getAllAccounts().length > 0
    //     ) {
    //         const accounts = this.authService.instance.getAllAccounts();
    //         this.authService.instance.setActiveAccount(accounts[0]);
    //     }
    // }

    loginRedirect() {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({
                ...this.msalGuardConfig.authRequest,
            } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    // loginPopup() {
    //     if (this.msalGuardConfig.authRequest) {
    //         this.authService
    //             .loginPopup({
    //                 ...this.msalGuardConfig.authRequest,
    //             } as PopupRequest)
    //             .subscribe((response: AuthenticationResult) => {
    //                 this.authService.instance.setActiveAccount(
    //                     response.account
    //                 );
    //             });
    //     } else {
    //         this.authService
    //             .loginPopup()
    //             .subscribe((response: AuthenticationResult) => {
    //                 this.authService.instance.setActiveAccount(
    //                     response.account
    //                 );
    //             });
    //     }
    // }

    // logout(popup?: boolean) {
    //     if (popup) {
    //         this.authService.logoutPopup({
    //             mainWindowRedirectUri: "/",
    //         });
    //     } else {
    //         this.authService.logoutRedirect();
    //     }
    // }

    ngOnDestroy(): void {
        this.destroying$.next(undefined);
        this.destroying$.complete();
    }

    // LoginNadel() {
    //     // this.authService.instance = new PublicClientApplication({
    //     //     auth: {
    //     //       clientId: environment.msalJNI.clientId,
    //     //       authority: environment.msalJNI.authority,
    //     //       redirectUri: environment.msalJNI.redirectUri,
    //     //       knownAuthorities: []
    //     //     }
    //     // });
    //     setTimeout(() => {
    //         this.loginRedirect();
    //     }, 1000);
    // }

    // LoginVendor() {
    //     this.loginRedirect();
    // }

    // getUserDetails(email: string) {
    //     this.userService.getUserDetails(email).subscribe((response: any) => {
    //         const userDetails = response.data;

    //         if (userDetails?.isActive) {
    //             sessionStorage.setItem(
    //                 "userDetails",
    //                 JSON.stringify(userDetails)
    //             );
    //             if (
    //                 userDetails.userRole === UserRole.Vendor
    //                 || userDetails.userRole === UserRole.SubVendor
    //             ) {
    //                 if (userDetails.vendor?.isFirstApproved === true) {
    //                     this.router.navigate(["/vendor/dashboard"]);
    //                 } else {
    //                     this.router.navigate(["/vendor/vendor-information"]);
    //                 }
    //             }
    //             if (userDetails.userRole === UserRole.Internal) {
    //                 this.router.navigate(["/dashboard"]);
    //             }
    //         } else {
    //             this.router.navigate(["/unauthorized"]);
    //         }
    //     });
    // }
}
