<div class="container-fluid login-screen">
    <div class="row">
        <div class="col-lg-6 col-md-6 logincover-bg"></div>
        <div class="col-lg-4 col-md-6 d-flex align-items-center justify-content-center login-cridentials">
            <div class="col-12 col-lg-8 col-md-11">
                <div class="col nadel-logo"><img src="assets/images/nadel-icon.webp" alt="Nadel Logo"></div>
                <h3>Unauthorized Access</h3>
                <p>You are not allowed to access this page.</p>

                <p-button (click)="onClickBack()" class="surface-900" label="Back to login" severity="secondary"></p-button>
            </div>
        </div>
        <div class="col-lg-2 col-md-2"></div>
    </div>
</div>
