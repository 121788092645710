import { CommonModule } from "@angular/common";
import {
    Component, Inject, OnDestroy, OnInit
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router, RouterOutlet } from "@angular/router";
import {
    MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalModule, MsalService
} from "@azure/msal-angular";
import {
    EventMessage, EventType, InteractionStatus
} from "@azure/msal-browser";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { DialogModule } from "primeng/dialog";
import { InputTextModule } from "primeng/inputtext";
import { filter, Subject, takeUntil } from "rxjs";

import { PushNotificationService } from "./core/helper/push-notification.service";
import { UserRole } from "./core/models/Enums/userRole.enum";
import { ThemeService } from "./core/services/theme.service";
import { UserService } from "./core/services/user.service";
import { SharedModule } from "./modules/shared/shared.module";

@Component({
    selector: "app-root",
    standalone: true,
    imports: [
        RouterOutlet,
        CalendarModule,
        FormsModule,
        SharedModule,
        CardModule,
        InputTextModule,
        ButtonModule,
        MsalModule,
        DialogModule,
        CommonModule

    ],
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit, OnDestroy {
    title = "jni-vendor-portal-ui";
    date: Date[] | undefined;
    email = "";
    routerObject;
    visible = false;

    isIframe = false;
    loginDisplay = false;
    private readonly destroying$ = new Subject<void>();
    multipleVendors: any = [];

    constructor(
        private userService: UserService,
        private notificationService: PushNotificationService,
        private router: Router,
        private themeService: ThemeService,
        @Inject(MSAL_GUARD_CONFIG)
        private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService
    ) {
        this.routerObject = router;
    }

    ngOnInit(): void {
        this.authService.handleRedirectObservable().subscribe();
        this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

        // this.setLoginDisplay();

        this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED
                        || msg.eventType === EventType.ACCOUNT_REMOVED
                )
            )
            .subscribe((result: EventMessage) => {
                console.log(result, "event");
                if (this.authService.instance.getAllAccounts().length === 0) {
                    window.location.pathname = "/";
                } else {
                    this.setLoginDisplay();
                }
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) => status === InteractionStatus.None
                ),
                takeUntil(this.destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            });
    }

    notify() {
        this.notificationService.notify(
            "jni-vendor-portal-ui",
            "Simulated Notification"
        );
    }

    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
        if (this.loginDisplay) {
            console.log("login success");

            const user = sessionStorage.getItem("userDetails");
            if (user) {
                const vendorDetails = JSON.parse(user);
                if (vendorDetails.vendorAccountNumber) {
                    this.getUserDetails(vendorDetails.vendorAccountNumber);
                } else {
                    this.getUserDetails("");
                }
            } else {
                this.getUserDetails("");
            }
        }
    }

    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        const activeAccount = this.authService.instance.getActiveAccount();

        if (
            !activeAccount
            && this.authService.instance.getAllAccounts().length > 0
        ) {
            const accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }
    }

    ngOnDestroy(): void {
        this.destroying$.next(undefined);
        this.destroying$.complete();
    }

    // getUserDetails(accountNumber: string) {
    //     this.userService.getUserDetails(accountNumber).subscribe((response: any) => {
    //         const userDetails = response.data;
    //         console.log(userDetails);
    //         if (userDetails?.isActive) {
    //             sessionStorage.setItem(
    //                 "userDetails",
    //                 JSON.stringify(userDetails)
    //             );

    //             if(userDetails.userRole === UserRole.Internal) {
    //                 this.router.navigate(["/dashboard"]);
    //             } else {
    //                 if(userDetails.vendor) {
    //                     this.visible = false;
    //                     if (userDetails.vendor?.isApproved === true) {
    //                         this.router.navigate(["/vendor/dashboard"]);
    //                     } else {
    //                         this.router.navigate(["/vendor/vendor-information"]);
    //                     }
    //                 } else {
    //                     //call getassociated api here
    //                     this.userService.getAssociations().subscribe((res: any) => {
    //                         this.multipleVendors = [];
    //                         Object.entries(res.data).forEach(([number, name]) => {
    //                             this.multipleVendors.push({ accountNumber: number, accountName: name });
    //                         });
    //                         this.visible = true;
    //                     });

    //                     this.router.navigate(["/vendor/dashboard"])
    //                 }
    //             }
    //         } else {
    //             this.router.navigate(["/unauthorized"]);
    //         }
    //     });
    // }

    // setVendor(accountNumber: string) {
    //     this.getUserDetails(accountNumber);
    // }

    getUserDetails(accountNumber: string) {
        this.userService.getUserDetails(accountNumber).subscribe((response: any) => {
            const userDetails = response.data;
            console.log(userDetails);

            if (userDetails?.isActive) {
                sessionStorage.setItem(
                    "userDetails",
                    JSON.stringify(userDetails)
                );

                if (userDetails.userRole === UserRole.Internal) {
                    this.router.navigate(["/dashboard"]);
                } else {
                    this.getAssociations();
                    if (userDetails.vendor) {
                        this.visible = false;
                        if (userDetails.vendor?.isApproved === true) {
                            // this.router.navigate(["/vendor/dashboard"]);
                            this.router.navigateByUrl("/refresh", { skipLocationChange: true }).then(() => {
                                this.router.navigate(["/vendor/dashboard"]);
                            });
                        } else {
                            // this.router.navigate(["/vendor/vendor-information"]);
                            this.router.navigateByUrl("/refresh", { skipLocationChange: true }).then(() => {
                                this.router.navigate(["/vendor/vendor-information"]);
                            });
                        }
                    } else {
                        this.visible = true;
                        this.router.navigate(["/vendor/dashboard"]);
                    }
                }
            } else {
                this.router.navigate(["/unauthorized"]);
            }
        });
    }

    setVendor(accountNumber: string) {
        this.visible = false;
        this.getUserDetails(accountNumber);
    }

    getAssociations() {
        this.userService.getAssociations().subscribe((res: any) => {
            this.multipleVendors = [];
            Object.entries(res.data).forEach(([number, name]) => {
                this.multipleVendors.push({ accountNumber: number, accountName: name });
            });
        });
    }
}
