<div class="sticky-top">
    @if(routerObject.url.includes("signup") || routerObject.url.includes("vendor") ||
    routerObject.url.includes("discussions") ||
    routerObject.url.includes("/dashboard")) {
    <app-header [multipleVendors]="multipleVendors" (vendorSelected)="setVendor($event)"></app-header>
    }

    <p-dialog header="Companies" [modal]="true" [(visible)]="visible" closable="false" [style]="{ width: '40rem' }">
        <div class="company-listing-popup mb-4">
            <!-- <div class="d-flex justify-content-end mb-3 switch"><i class="pi pi-arrow-right-arrow-left"></i></div> -->

            <div class="list-group">
                <a style="cursor: pointer;" (click)="setVendor(vendor.accountNumber)" *ngFor="let vendor of multipleVendors"
                    class="list-group-item list-group-item-action">{{ vendor.accountName }}</a>
            </div>
        </div>
    </p-dialog>
</div>
<router-outlet />

<!-- <app-vendor-summery></app-vendor-summery> -->