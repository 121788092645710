<div class="container-fluid login-screen p-0 overflow-hidden">
    <div class="row">
        <div class="col-lg-12 d-flex justify-content-end">
            <div class="col-lg-6 position-fixed top-0 d-flex justify-content-center align-items-center color-box spinner-contr">
                <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="4"></p-progressSpinner>
              
            </div>
            <span class="d-flex justify-content-center align-items-center mt-5 pt-5 w-100">
                <h1 class="mt-5 pt-5">REDIRECTING</h1>    
            </span>
        </div>
    </div>
</div>