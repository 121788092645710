import {
    Component, OnInit
} from "@angular/core";
import {
    MsalService
} from "@azure/msal-angular";
import { ButtonModule } from "primeng/button";

@Component({
    selector: "app-unauthorized",
    standalone: true,
    imports: [ButtonModule],
    templateUrl: "./unauthorized.component.html",
    styleUrl: "./unauthorized.component.scss"
})
export class UnauthorizedComponent implements OnInit {
    constructor(
        private authService: MsalService,
    ) {
    }
    ngOnInit() { }
    onClickBack(popup?: boolean) {
        if (popup) {
            this.authService.logoutPopup({
                mainWindowRedirectUri: "/",
            });
        } else {
            this.authService.logoutRedirect();
        }
    }
}
